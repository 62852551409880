html, body,
* {
	position: relative;
    cursor: none !important;
	-ms-overflow-style: none;
  	scrollbar-width: none; 
}
body::-webkit-scrollbar {
  display: none;
}

/*! home */

.name {
	position: absolute;
	width: calc(100% - 136px);
	max-width: 1920px;
	margin-top: 22.22vh;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
	z-index: 8;
}

@media screen and (min-width: 2561px) {
    .name {
        max-width: 75vw;
    }
    .name span {
        font-size: 8vw;
        line-height: 9vw;
    }
}
@media screen and (max-width: 1250px) {
	.name {
		flex-direction: column;
		width: 100%;
	}
	.spacer {
		display: none;
	}
}

/*! cursor */

.mouse {
	position: fixed;
	display: none;
	width: 0px;
	height: 0px;
	overflow: visible;
	z-index: 99999;
	pointer-events: none;
}
.cursor {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 50%;
    top: 50%;
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.7;
}

/*! navigation */

.navigation {
	z-index: 8;
	position: absolute;
	top: 40px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 0 80px;
}
@media screen and (min-width: 1250px) {
	.navigation {
		background-color: inherit;
	}
}
@media screen and (max-width: 1250px) {
	.navigation {
		top: 0;
		padding: 15px 5%;
		z-index: 9;
	}
}
@media screen and (max-width: 800px) {
	.navigation {
		padding: 12px 5%;
	}
}
@media screen and (max-width: 500px) {
	.navigation {
		padding: 10px 5%;
	}
}

/*! projects */

.projects {
	width: 100%;
	height: 100svh;
}
.projects .wrap {
	width: 100%;
	height: 100%;
}
.projectContainer {
	width: 100%;
	height: 100%;
}
.project {
	width: 100%;
	height: 79.26%;
}
.projectContainer:not(:last-child) .line {
	content: '';
	position: absolute;
	right: 0;
	bottom: 0;
	height: 0;
	width: 1px;
	opacity: 0.2;
}
.number {
	position: absolute;
	bottom: 40px;
}
.projectDetails {
	position: fixed;
	width: calc(100% - 160px);
	left: 80px;
	bottom: 41px;
	opacity: 0;
	pointer-events: none;
}
.projectDetails .projectName {
	min-width: 250px;
	margin-right: 80px;
}
.projectDetails .keywords {
	margin-right: 80px;
}
.projectDetails .keywords span:not(:last-child) {
	margin-right: 24px;
}
.projectDetails .year {
	margin-left: auto;
}
.media {
	position: fixed;
	z-index: 8;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 60.833vw;
	max-width: 1168px;
	pointer-events: none;
	opacity: 0;
}
.media > * {
	width: 100% !important;
	height: auto !important;
}

@media screen and (min-width: 2561px) {
    .media {
        max-width: 110vmin;
    }
}
@media screen and (max-width: 1250px) {
	.projects {
		overflow: auto;
	}
	.projects .wrap {
		height: auto;
		padding: 50vh 0 50px 0;
		flex-direction: column;
	}
	.projectContainer {
		margin-top: 10vh;
		backdrop-filter: 10px;
	}
	.project {
		display: none;
	}
	.media {
		width: 96vw;
		position: relative;
		transform: inherit !important;
		opacity: 1;
		top: inherit;
		left: inherit;
	}
	.projectDetails {
		position: relative;
		width: 90%;
		margin-top: 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		order: 2;
		opacity: 1;
		left: inherit;
		bottom: inherit;
		text-align: center;
	}
	.projectDetails > * {
		margin: 0 auto !important;
	}
	.projectDetails span {
		margin: 0px auto !important;
	}
	.projectName {
		order: 1;
		margin: 0 auto;
	}
	.year {
		order: 2;
		padding: 8px 0;
		margin: 0 auto;
	}
	.keywords {
		order: 3;
		flex-direction: column;
		margin: 0 auto;
	}
	.createdAt {
		order: 4;
		margin: 0 auto;
	}
	.number {
		display: none;
	}
}

/*! about */

.about {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100svh;
	overflow: hidden;
	z-index: 9;
}
.content {
	position: absolute;
	left: 0;
	top: 0;
	padding: 80px 160px 80px 80px;
	width: 100vw;
	height: 100%;
	overflow: auto;
}
.text {
	max-width: 1278px;
}
.moreInfo {
	margin-top: 40px;
}
.contact {
	width: 100%;
}
.contact a:not(:last-child) {
	margin-right: 80px;
}
.close {
	position: absolute;
	right: 0;
	top: 0;
	width: 60px;
	height: 100%;
}
.close span {
	position: absolute;
	top: 55px;
	transform: rotate(90deg);
}
.moreInfoTxt {
	overflow: hidden;
}

@media screen and (max-width: 1250px) {
	.contact {
		flex-direction: column;
	}
	.contactMail {
		margin-top: 14px;
	}
	.content {
		padding: 5vw calc(5vw + 60px) 5vw 5vw;
	}
	.close {
		width: 60px;
	}
	.contact a:not(:last-child) {
		margin-right: 60px;
	}
}
@media screen and (max-width: 800px) {
	.close {
		width: 50px;
	}
	.contact a:not(:last-child) {
		margin-right: 40px;
	}
}
@media screen and (max-width: 500px) {
	.close {
		width: 40px;
	}
	.contact a:not(:last-child) {
		margin-right: 24px;
	}
}