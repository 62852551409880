/*! themes */

/*
#0C3B39
#5DABA8

#B08E02
#FDE14F

#C92104
#FF7959
*/

/*? theme 1 */
.theme1 .b1 {
	background-color: #1E2666;
}
.theme1 .b2 {
	background-color: #5664D2;
}
.theme1 .c1,
.theme1 .c1 span,
.theme1 .c1 a {
	color: #1E2666;
}
.theme1 .c2,
.theme1 .c2 span,
.theme1 .c2 a {
	color: #5664D2;
}
.theme1 ::selection,
.theme1 .text-selection {
	background: rgba(86, 100, 210, 0.99);
}
.theme1 ::-moz-selection {
	background: rgba(86, 100, 210, 0.99);
}
.theme1 p a {
	color: #5664D2;
}
/*? theme 2 */
.theme2 .b1 {
	background-color: #FB9747;
}
.theme2 .b2 {
	background-color: #AE4204;
}
.theme2 .c1,
.theme2 .c1 span,
.theme2 .c1 a {
	color: #FB9747;
}
.theme2 .c2,
.theme2 .c2 span,
.theme2 .c2 a {
	color: #AE4204;
}
.theme2 ::selection,
.theme2 .text-selection {
	background: rgba(174, 66, 4, 0.99);
}
.theme2 ::-moz-selection {
	background: rgba(174, 66, 4, 0.99);
}
.theme2 p a {
	color: #AE4204;
}
/*? theme 3 */
.theme3 .b1 {
	background-color: #5DABA8;
}
.theme3 .b2 {
	background-color: #0C3B39;
}
.theme3 .c1,
.theme3 .c1 span,
.theme3 .c1 a {
	color: #5DABA8;
}
.theme3 .c2,
.theme3 .c2 span,
.theme3 .c2 a {
	color: #0C3B39;
}
.theme3 ::selection,
.theme3 .text-selection {
	background: rgba(12, 59, 57, 0.99);
}
.theme3 ::-moz-selection {
	background: rgba(12, 59, 57, 0.99);
}
.theme3 p a {
	color: #0C3B39;
}
/*? theme 4 */
.theme4 .b1 {
	background-color: #B08E02;
}
.theme4 .b2 {
	background-color: #FDE14F;
}
.theme4 .c1,
.theme4 .c1 span,
.theme4 .c1 a {
	color: #B08E02;
}
.theme4 .c2,
.theme4 .c2 span,
.theme4 .c2 a {
	color: #FDE14F;
}
.theme4 ::selection,
.theme4 .text-selection {
	background: rgba(253, 225, 79, 0.99);
}
.theme4 ::-moz-selection {
	background: rgba(253, 225, 79, 0.99);
}
.theme4 p a {
	color: #FDE14F;
}
/*? theme 5 */
.theme5 .b1 {
	background-color: #FF7959;
}
.theme5 .b2 {
	background-color: #C92104;
}
.theme5 .c1,
.theme5 .c1 span,
.theme5 .c1 a {
	color: #FF7959;
}
.theme5 .c2,
.theme5 .c2 span,
.theme5 .c2 a {
	color: #C92104;
}
.theme5 ::selection,
.theme5 .text-selection {
	background: rgba(201, 33, 4, 0.99);
}
.theme5 ::-moz-selection {
	background: rgba(201, 33, 4, 0.99);
}
.theme5 p a {
	color: #C92104;
}

::selection,
.text-selection {
	color: #fff !important;
	-webkit-text-fill-color: #fff !important;
}
::-moz-selection {
	color: #ffffff !important;
}
img::selection,
img::-moz-selection {
	background: transparent;
}
p a:hover {
	color: #fff;
}

/*! fonts */

@font-face {
    font-family: 'Inter';
    src: local('Inter Regular'),
        url('./fonts/inter/Inter-Regular.woff2') format('woff2'),
        url('./fonts/inter/Inter-Regular.woff') format('woff'),
        url('./fonts/inter/Inter-Regular.ttf') format('truetype'),
        url('./fonts/inter/Inter-Regular.svg#Inter') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
   font-family: 'Monas';
    src: local('Monas'),
        url('./fonts/monas/Monas.woff2') format('woff2'),
        url('./fonts/monas/Monas.woff') format('woff'),
        url('./fonts/monas/Monas.ttf') format('truetype'),
        url('./fonts/monas/Monas.svg#Monas') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/*! defaults */

body, html {
	height: 100%;
	min-height: 100%;
	font-size: 20px; /* Please modify Generic Font Size Responsive in last rows */
	line-height: 1.5rem;
	color: #fff;
	overflow: hidden !important;
}
body {
	position: relative;
	background-color: #0B1522;
	width: inherit;
	height: inherit;
}
body, table, input, select, textarea, pre {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	color: #fff;
}
div, section, span, p, a {
	position: relative;
}
span, a, p {
	color: #fff;
}

/* Table */
table {
	border: 0;
	border-collapse: collapse;
	width: 100%;
}
table caption {
	font-size: 1rem;
}
table colgroup col {
	width: 29.572650%;
}
table colgroup col:first-child {
	width: 5.470085%;
}
table thead {
	border-bottom: solid 2px #BFBFBF;
}
table thead th {
	padding-top: 6px;
	padding-bottom: 6px;
	text-align: left;
}
table tfoot td {
	border-top: solid 1px #BFBFBF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tbody td {
	border-left: 2px solid #FFFFFF;
	border-right: 2px solid #FFFFFF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tr * {
	padding-left: 1%;
	padding-right: 1%;
}

/* List */
ul li {
	left: 0;
	text-indent: -4px;
	padding-left: 30px;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	padding-bottom: 8px;
}
ul li::before {
	content: '';
	position: relative;
	display: inline-block;
	background: #ffffff;
	height: 0.3rem;
	width: 0.3rem;
	top: -0.25rem;
	left: -21px;
	border-radius: 50%;
}
.c2 ul li::before {
	background: #939393;
}
ul p {
	display: inline;
}

/*! typography */
.inter {
	font-family: 'Inter', sans-serif !important;
}
.monas, 
.monas span {
	font-family: 'Monas', sans-serif !important;
}

.t1, .t1 a, 
.t1 span, .t1 li {
	font-family: 'Monas', sans-serif;
	font-size: 10rem;
	line-height: 10rem;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.2rem;
}
.font-default, .font-default p, .font-default span, legend, select, input, textarea {
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
}
.font-medium, .font-medium p, .font-medium a {
	font-size: 1.8rem;
	line-height: 2.18rem;
	font-weight: 400;
}
.font-small, .font-small p, .font-small a {
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
}

/*! responsive */

@media screen and (max-width: 1650px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 9rem;
		line-height: 9rem;
	}
}

@media screen and (max-width: 1450px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 8.5rem;
		line-height: 8.5rem;
	}
}

@media screen and (max-width: 1225px) {
	body, html {
		font-size: 19px;
	}
}

@media screen and (max-width: 1125px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 8rem;
		line-height: 8rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.7rem;
		line-height: 2.08rem;
	}
}

@media screen and (max-width: 950px) {
	body, html {
		font-size: 18px;
	}
}

@media screen and (max-width: 850px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 7.5rem;
		line-height: 7.5rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.6rem;
		line-height: 1.98rem;
	}
}

@media screen and (max-width: 700px) {
	body, html {
		font-size: 17px;
	}
	ul li {
		padding-left: 20px;
	}
	ul li::before {
		left: -15px;
	}
}

@media screen and (max-width: 600px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 7rem;
		line-height: 7rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.5rem;
		line-height: 1.88rem;
	}
}

@media screen and (max-width: 500px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 6rem;
		line-height: 6rem;
	}
	body, html {
		font-size: 16px;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.4rem;
		line-height: 1.78rem;
	}
}

@media screen and (max-width: 425px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 5rem;
		line-height: 5rem;
	}
	body, html {
		font-size: 15px;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.35rem;
		line-height: 1.73rem;
	}
}


/* --------------------------------------------- END Generic Font Size Responsive END --------------------------------------------- */

/* --------------------------------------------- END Responsive END --------------------------------------------- */